import {computed} from 'vue';
import store from "@/store";

export const enum HtmlAddPositionType {
	ADD_BEFORE = 'add_before',
	ADD_AFTER = 'add_after',
	APPEND = 'append',
}

export default function useCustomStore() {
	const getPageReloaded = computed(() => store.getters['custom/getPageReload']);
	const getAdBlocker = computed(() => store.getters['custom/getAdBlocker']);
	const isAdBlockerCheckRunning = computed(() => store.getters['custom/getAdBlockerCheckRunning']);
	const getAdBlockerCheckUrl = computed(() => store.getters['custom/getAdBlockerCheckUrl']);
	const getCookies = computed(() => store.getters['custom/getCookies']);
	const getCookieDetails = computed(() => store.getters['custom/getCookieDetails']);
	const getLanguageLocale = computed(() => store.getters['custom/getLanguageLocale']);

	function isFullCookieConsentAllowed(): boolean
	{
		return getCookieDetails.value.all === true;
	}

	function isMinimalCookieConsentAllowed(): boolean
	{
		return getCookieDetails.value.minimal === true;
	}

	function isMobileDevice(): boolean
	{
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}

	function stringToHash(value: string) {
		let hash = 0;
		if (value.length == 0) return hash;

		for (let i = 0; i < value.length; i++) {
			const char = value.charCodeAt(i);
			hash = ((hash << 5) - hash) + char;
			hash = hash & hash;
		}

		return hash;
	}

	function scriptElementToHash(scriptElement: HTMLScriptElement): string
	{
		let result = '';
		result += scriptElement.src + scriptElement.text + scriptElement.getAttributeNames().join(',');
		return result.trim();
	}

	// Function to check if script is already appended to the DOM
	function isLinkAlreadyAppended(script: HTMLScriptElement) {
		const scripts = Array.from(document.getElementsByTagName('script'));
		let result = false;
		scripts.forEach((s) => (scriptElementToHash(s) == scriptElementToHash(script) ? (result = true) : null));
		return result;
	}

	// Function that creates our script element. Also adds attributes if that
	// parameter is filled with an object
	function createScriptElement(script: string, attributes = {}, scriptContent = '', scriptType = '') {
		const el = document.createElement('script');
		if (script.length > 0) {
			el.src = script;
		}
		let key: any;
		let value: any;
		for ([key, value] of Object.entries(attributes)) {
			el.setAttribute(key, value);
		}
		if (scriptContent.length > 0) {
			el.text = scriptContent;
		}
		if (scriptType.length > 0) {
			el.type = scriptType;
		}
		return el;
	}

	// Function that appends script to the Head. Could also be adjusted to prepend
	// it before the closing </body> if you want to.
	function appendScriptElement(el: any, tagName: string|HTMLElement = 'head', addPosition: HtmlAddPositionType = HtmlAddPositionType.APPEND, ignoreAlreadyAppendedCheck = false) {
		if (ignoreAlreadyAppendedCheck || !isLinkAlreadyAppended(el)) {
			if (addPosition === HtmlAddPositionType.ADD_BEFORE) {
				if (tagName instanceof Element) {
					tagName.before(el);
				} else {
					document.getElementsByTagName(tagName)[0].before(el)
				}
			} else if (addPosition === HtmlAddPositionType.ADD_AFTER) {
				if (tagName instanceof Element) {
					tagName.after(el);
				} else {
					document.getElementsByTagName(tagName)[0].after(el)
				}
			} else if (addPosition === HtmlAddPositionType.APPEND) {
				if (tagName instanceof Element) {
					tagName.appendChild(el);
				} else {
					document.getElementsByTagName(tagName)[0].appendChild(el)
				}
			}
		}
	}

	function setPageReloaded(value: boolean) {
		store.commit('custom/setPageReload', value)
	}

	function setAdBlocker(value: boolean) {
		store.commit('custom/setAdBlocker', value)
	}

	function setAdBlockerCheckRunning(value: boolean) {
		store.commit('custom/setAdBlockerCheckRunning', value)
	}

	function setAdBlockerCheckUrl(value: string) {
		store.commit('custom/setAdBlockerCheckUrl', value)
	}

	function setCookies(value: boolean) {
		store.commit('custom/setCookies', value)
	}

	function setCookieDetails(value: any) {
		store.commit('custom/setCookieDetails', value)
	}

	function setLanguageLocale(value: string) {
		store.commit('custom/setLanguageLocale', value)
	}

	function getLanguageByNavigator(options = {}): string[]|undefined {
		const defaultOptions = {
			languageCodeOnly: false,
		};
		const opt = {
			...defaultOptions,
			...options,
		};
		const browserLocales =
			navigator.languages === undefined
				? [navigator.language]
				: navigator.languages;
		if (!browserLocales) {
			return undefined;
		}
		return browserLocales.map(locale => {
			const trimmedLocale = locale.trim();
			return opt.languageCodeOnly
				? trimmedLocale.split(/-|_/)[0]
				: trimmedLocale;
		});
	}

	function getDefaultBrowserLanguageCode(): string
	{
		let result: any = 'en';
		const arrLanguageMap = getLanguageByNavigator({languageCodeOnly: true});
		if (arrLanguageMap) {
			result = arrLanguageMap.shift();
		}

		return result;
	}

	return {
		getPageReloaded,
		getAdBlocker,
		getAdBlockerCheckUrl,
		getCookies,
		getCookieDetails,
		getLanguageLocale,
		isAdBlockerCheckRunning,
		getLanguageByNavigator,
		getDefaultBrowserLanguageCode,
		setPageReloaded,
		setAdBlocker,
		setAdBlockerCheckRunning,
		setAdBlockerCheckUrl,
		setCookies,
		setCookieDetails,
		setLanguageLocale,
		createScriptElement,
		appendScriptElement,
		isFullCookieConsentAllowed,
		isMinimalCookieConsentAllowed,
		isMobileDevice
	}
}
