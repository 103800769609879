import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import store from "@/store";
import middlewarePipeline from "@/router/middlewarePipeline";
import Tabs from "@/views/Tabs.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		redirect: '/dice/home',
		meta: {
			title: "Dicy | Roll the dice",
			metaTags: [
				{
					name: 'Dicy | Roll the dice',
					content: 'Get Inspired and grow new habits. Start today and grow for tomorrow.'
					// content: 'FooBAR' + i18n.global.t("auth.RegisterComponent.registerTitle") + ', Get Inspired and grow new habits. Choose your Boostpack and get daily motivation content on your preferred channel. Start today and grow for tomorrow.'
				},
				{
					property: 'og:description',
					content: 'Get Inspired and grow new habits. Start today and grow for tomorrow.'
				},
				{
					property: 'description',
					content: 'Get Inspired and grow new habits. Start today and grow for tomorrow.'
				},
				{
					name: 'keywords',
					content: 'ideas, ai, generate ideas, dicy, gtp '
				}
			],
		}
	},
	{
		path: '/home',
		redirect: '/dice/home',
		meta: {
			title: "Dicy | Roll the dice",
			metaTags: [
				{
					name: 'Dicy | Roll the dice',
					content: 'Get Inspired and grow new habits. Start today and grow for tomorrow.'
					// content: 'FooBAR' + i18n.global.t("auth.RegisterComponent.registerTitle") + ', Get Inspired and grow new habits. Choose your Boostpack and get daily motivation content on your preferred channel. Start today and grow for tomorrow.'
				},
				{
					property: 'og:description',
					content: 'Get Inspired and grow new habits. Start today and grow for tomorrow.'
				},
				{
					property: 'description',
					content: 'Get Inspired and grow new habits. Start today and grow for tomorrow.'
				},
				{
					name: 'keywords',
					content: 'ideas, ai, generate ideas, dicy, gtp '
				}
			],
			middleware: []
		},
	},
	{
		path: '/dice/',
		component: Tabs,
		meta: {
			title: "Dicy | Roll the dice",
			metaTags: [
				{
					name: 'Dicy | Roll the dice',
					content: 'Get Inspired and grow new habits. Start today and grow for tomorrow.'
					// content: 'FooBAR' + i18n.global.t("auth.RegisterComponent.registerTitle") + ', Get Inspired and grow new habits. Choose your Boostpack and get daily motivation content on your preferred channel. Start today and grow for tomorrow.'
				},
				{
					property: 'og:description',
					content: 'Get Inspired and grow new habits. Start today and grow for tomorrow.'
				},
				{
					property: 'description',
					content: 'Get Inspired and grow new habits. Start today and grow for tomorrow.'
				},
				{
					name: 'keywords',
					content: 'ideas, ai, generate ideas, dicy, gtp '
				}
			],
			middleware: []
		},
		children: [
			{
				path: '',
				redirect: '/dice/home',
				meta: {
					title: "Dicy | Roll the dice",
					metaTags: [
						{
							name: 'Dicy | Roll the dice',
							content: 'Get Inspired and grow new habits. Start today and grow for tomorrow.'
							// content: 'FooBAR' + i18n.global.t("auth.RegisterComponent.registerTitle") + ', Get Inspired and grow new habits. Choose your Boostpack and get daily motivation content on your preferred channel. Start today and grow for tomorrow.'
						},
						{
							property: 'og:description',
							content: 'Get Inspired and grow new habits. Start today and grow for tomorrow.'
						},
						{
							property: 'description',
							content: 'Get Inspired and grow new habits. Start today and grow for tomorrow.'
						},
						{
							name: 'keywords',
							content: 'ideas, ai, generate ideas, dicy, gtp '
						}
					],
					middleware: []
				},
			},
			{
				path: 'home',
				component: () => import('@/views/Tabs/HomeTab.vue'),
				meta: {
					middleware: []
				},
			},
			{
				path: 'history',
				component: () => import('@/views/Tabs/HistoryTab.vue'),
				meta: {
					middleware: []
				},
			},
			{
				path: '/dice/shared/:hash',
				component: () => import('@/views/SharedDicy.vue'),
				meta: {
					title: "Dicy | Shared Dice",
					metaTags: [
						{
							name: 'Dicy | Shared Dice',
							content: 'Get Inspired by shared content.'
							// content: 'FooBAR' + i18n.global.t("auth.RegisterComponent.registerTitle") + ', Get Inspired and grow new habits. Choose your Boostpack and get daily motivation content on your preferred channel. Start today and grow for tomorrow.'
						},
						{
							property: 'og:description',
							content: 'Get Inspired by shared content.'
						},
						{
							property: 'description',
							content: 'Get Inspired by shared content.'
						},
						{
							name: 'keywords',
							content: 'ideas, ai, shared, generate ideas, dicy, gtp '
						}
					],
					middleware: []
				},
			},
		]
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: '/home',
	},
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

const router = createRouter({
	history: createWebHistory(),
	routes
})


router.beforeEach((to, from, next) => {
	if (!to.meta.middleware || !(to.meta.middleware instanceof Array) || to.meta.middleware.length < 1) {
		return next();
	}

	const context = {
		to,
		from,
		next,
		store
	}

	return middlewarePipeline(context, to.meta.middleware, 0)();
})

export default router
