const dice = {
	namespaced: true,
	state: () => ({
		currentDice: {value: null, description: ''},
		diceHistory: {}
	}),
	mutations: {
		// eslint-disable-next-line
		setTutorialCompleted(state: any, value: boolean) {
			state.tutorialCompleted = value;
		},
	},
	actions: {},
	getters: {
	}
}

export default dice;