import {Store, createLogger} from 'vuex'
import {createStore} from 'vuex-extensions'
import auth from "@/store/modules/auth";
import custom from "@/store/modules/custom";
import dice from "@/store/modules/dice";

import VuexPersistence from 'vuex-persist'

const debug = process.env.NODE_ENV !== 'production'

const vuexLocal = new VuexPersistence({
	storage: window.localStorage
})

export default createStore(Store, {
	state: {},
	mutations: {},
	actions: {},
	modules: {
		auth,
		custom,
		dice,
	},
	strict: debug,
	plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin]
})
