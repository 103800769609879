
import {
	IonIcon,
	IonLabel,
	IonPage,
	IonTabBar,
	IonTabButton,
	IonTabs,
	IonRouterOutlet,
} from '@ionic/vue';
import * as icons from 'ionicons/icons';

export default {
	name: 'Tabs',
	components: {IonTabs, IonPage, IonRouterOutlet},
	setup() {
		return {icons}
	}
}
